@layer utilities {
  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity 0.3s, transform 0.3s;
  }
  .modal-fade-enter, .modal-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateY(40px);
  }

  .modal-open {
    position: fixed;
    overflow: hidden;
    right: 0;
    left: 0;
  }

  .text-reveal-enter-active,
  .text-reveal-leave-active {
    transition: opacity 0.3s, height 0.3s;
  }
  .text-reveal-enter, .text-reveal-leave-to /* .fade-leave-active below version 2.1.8 */ {
    height: 0;
    opacity: 0;
  }

  .text-reveal {
    opacity: 1;
    height: auto;
  }


  .sir-block {
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity .5s
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0
    }
  }
}

.careers-block {
  .fade-enter-active,
  .fade-leave-active {
      transition: opacity 1s
  }


  .fade-enter,
  .fade-leave-to {
      opacity: 0
  }
}


.section-accordion-carousel {
  .fade-enter-active,
  .fade-leave-active {
      transition: opacity 0.4s
  }


  .fade-enter,
  .fade-leave-to {
      opacity: 0
  }
}


.carousel-product {
  .fade-enter-active,
  .fade-leave-active {
      transition: opacity 0.4s
  }


  .fade-enter,
  .fade-leave-to {
      opacity: 0
  }
}

@layer utilities {
  // ----- HEADINGS -----
  /*! purgecss start ignore */
  .h-emph {
    & b,
    & strong {
      @apply font-bold;
      &::before,
      &::after {
        content: '';
        display: block;
      }
    }

    &--mob {
      & b,
      & strong {
        @apply inline-block;

        &::before,
        &::after {
          @screen sm {
            @apply hidden;
          }
        }
      }
    }
  }

  .h-bold {
    & b,
    & strong {
      @apply font-bold;
    }
  }

  .md\:h-emph {
    & b,
    & strong {
      @apply font-bold;

      @screen md {
        &::before {
          content: '';
          display: block;
        }
      }
    }
  }

  .md\:b-break {
    & b,
    & strong {
      @apply block font-bold;

      @screen md {
        @apply inline;
      }
    }
  }
  /*! purgecss end ignore */

  .h1 {
    @apply font-semibold leading-tightest text-50 -tracking-20;

    &--mob-sm {
      @apply text-48;

      @screen sm {
        @apply text-64;
      }
    }

    @screen md {
      @apply leading-none text-96 -tracking-30;
    }

    &--emph {
      @apply font-heavy leading-overlap uppercase;
    }
  }

  .h2 {
    @apply leading-tighter text-48 -tracking-10;

    @screen md {
      @apply leading-tightest text-64 -tracking-15;
    }

    b, strong {
      @apply font-bold;
    }
  }

  .h2-ii {
    @apply leading-tight text-36 -tracking-5;

    @screen md {
      @apply leading-tighter text-48 -tracking-10;
    }

    @screen lg {
      @apply leading-tightest text-64 -tracking-15;
    }
  }

  .h3 {
    @apply leading-tight text-36 -tracking-5;

    @screen md {
      @apply leading-tighter text-48 -tracking-10;
    }

    b, strong {
      @apply font-bold;
    }
  }

  .h4 {
    @apply leading-tight text-30;

    @screen md {
      @apply text-32;
    }
  }

  .h5 {
    @apply font-semibold leading-tight text-22;
  }

  .h6 {
    @apply font-semibold leading-tight text-16 tracking-10;
  }


  // ----- PARAGRAPHS -----
  // .p {
  //
  // }


  // ----- OTHER TYPOGRAPHY CLASSES -----
  .caps {
    @apply font-bold leading-tight text-13 tracking-20 uppercase;

    &--lg {
      @apply text-15 tracking-25;
    }

    &--sm {
      @apply text-11;
    }

    &--xs {
      @apply text-10 tracking-15;
    }
  }

  .caption {
    @apply max-w-620 mt-16 mx-auto px-20 text-13 text-center text-grey-500 w-full;
  }

  .label {
    @apply flex font-extrabold leading-none text-22 uppercase;

    &::before,
    &::after {
      border-left: 10px solid;
      content: '';
      transform: skewX( -15deg );
    }

    &::before {
      @apply -mr-4;
    }

    &::after {
      @apply -ml-4;
    }
  }

  .label__inner {
    @apply pb-4 px-10 pt-6;
  }


  // ----- LINKS -----
  .link-i {
    @apply duration-300 ease-in-out text-blue-400;

    &:focus,
    &:hover {
      @apply text-blue-500;
    }
  }

  .link-ii {
    @apply duration-300 ease-in-out text-blue-900;

    &:focus,
    &:hover {
      @apply text-blue-400;
    }
  }

  .link-iii {
    @apply duration-300 ease-in-out;

    &:focus,
    &:hover {
      @apply text-blue-500;
    }
  }

  // ----- RICHTEXT -----
  /*! purgecss start ignore */

  .basic-text {
    h3 {
      @apply text-22 font-semibold leading-normal;
    }

    p {
      @apply text-18 leading-normal;

      @screen md {
        @apply text-20;
      }
    }

    * + p {
      @apply mt-24;
    }

    * + h3 {
      @apply mt-40;
    }

    &--small {
      h3 {
        @apply text-18;
      }

      p {
        @apply text-15;
      }
    }
  }

  .richtext {
    & a {
      @apply duration-300 ease-in-out text-blue-400;

      &:focus,
      &:hover {
        @apply text-black;
      }
    }

    &--light {
      & a {
        &:focus,
        &:hover {
          @apply text-blue-200;
        }
      }
    }

    & h2,
    & h3,
    & h4 {
      @apply font-bold mb-text-sm text-22 text-blue-900;

      &:not(:first-child) {
        @apply mt-text-xl;
      }
    }

    & p,
    & ul,
    & ol {
      margin-bottom: 1.5em;
    }

    & ul,
    & ol {
      &:not(:first-child) {
        margin-top: 1.5em;
      }
    }

    & ol {
      list-style-type: decimal;
      list-style-position: outside;
      padding-left: 1.5em;
    }

    & li {
      margin-bottom: 0.4em;
      padding-left: 0.5em;

      & ul,
      & ol {
        margin-top: 1em;
      }
    }

    & hr {
      @apply border-0 border-t-0 border-grey-300 mx-0 my-16;
    }

    & iframe {
      @apply max-w-full mx-auto w-full;
    }

    & table {
      @apply block mb-text-xl overflow-scroll text-13 w-full;

      & td,
      & th {
        @apply border-current p-8;
      }

      & th,
      & strong {
        @apply text-blue-900;
      }

      @screen md {
        @apply table overflow-hidden text-15;
      }
    }

    &--description-spaced {
      & ol,
      & ul {
        & > li {
          margin-bottom: 0.75em;
        }
      }
    }

    &--spaced-sm {
      & p {
        margin-bottom: 0.4em;
      }
    }

    &--spaced-md {
      & p {
        margin-bottom: 1em;
      }
    }

    &--tips {
      & ul,
      & ol {
        margin-bottom: 1.75em;

        &:not(:first-child) {
          margin-top: 1.75em;
        }
      }

      & li {
        @apply text-blue-900;
      }
    }

    &--li-blocks {
      & ul,
      & ol {
        @apply p-0;
      }

      & li {
        @apply bg-white-75% m-0 px-24 py-20 rounded-6 shadow-grey-20%-md;

        &:not( :last-child ) {
          @apply mb-10;
        }
      }

      & strong {
        @apply text-blue-900;
      }
    }

    &--body {
      & a {
        @apply underline;
      }

      & h2,
      & h3,
      & h4 {
        @apply font-medium mb-24;
      }

      & h2 {
        @apply leading-tight text-30;

        @screen md {
          @apply text-32;
        }
      }

      & hr {
        @apply my-50;
      }
    }

    p,
    li,
    & > ul,
    & > ol {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  ul {
    list-style-type: disc;
  }
  /*! purgecss end ignore */
}

@layer components {
  .btn {
    @apply border caps duration-400 ease-in-out inline-flex items-center justify-center text-center;

    &--pri {
      @apply bg-blue-400 border-blue-400 rounded-6 text-white;

      &:hover,
      &:focus {
        @apply bg-blue-500 border-blue-500;
      }
    }

    &--sec {
      @apply bg-white border-grey-300 rounded-6 text-blue-400;

      &:hover,
      &:focus {
        @apply bg-white border-blue-400;
      }
    }

    &--ter {
      @apply bg-white border-grey-300 rounded-6 text-red-500;

      &:hover,
      &:focus {
        @apply bg-white border-red-500;
      }
    }

    &--lg {
      @apply min-w-300 px-24;
      padding-bottom: 1.35rem;
      padding-top: 1.45rem;
    }

    &--md {
      @apply min-w-200 p-16;
    }

    &--icon-pri {
      @apply bg-white border-grey-300 flex-shrink-0 rounded-full text-10 text-blue-400;

      &:hover,
      &:focus,
      &.btn--disabled-loading[disabled] {
        @apply bg-blue-400 border-blue-400 text-white;
      }
    }

    &--icon-sec {
      @apply bg-blue-400 border-blue-400 flex-shrink-0 rounded-full text-11 text-white;

      &:hover,
      &:focus,
      &.btn--disabled-loading[disabled] {
        @apply bg-white border-grey-300 text-blue-400;
      }
    }

    &--icon-ter {
      @apply bg-blue-400 border-blue-400 flex-shrink-0 rounded-full text-11 text-white;

      &:hover,
      &:focus,
      &.btn--disabled-loading[disabled] {
        @apply border-grey-300 bg-grey-300 text-grey-700;
      }
    }

    &--icon-sm {
      @apply h-42 w-42;
    }

    &--icon-md {
      @apply h-48 w-48;
    }

    &--icon-lg {
      @apply h-64 w-64;
    }

    &--icon-xl {
      @apply h-64 w-64;

      @screen lg {
        @apply h-80 w-80;
      }
    }

    &--disabled-hide {
      &[disabled] {
        @apply opacity-0 pointer-events-none select-none;
      }
    }

    &--disabled-show {
      &[disabled] {
        @apply bg-blue-500 border-blue-500 pointer-events-none select-none;
      }
    }

    &--footer {
      &:active,
      &:focus {
        @apply bg-blue-700;
      }
      &[disabled] {
        @apply border-grey-300 text-grey-300;
      }
    }
    &--footer-xs {
      @apply rounded-60 px-20 py-16 lg:hidden;
      &:disabled {
        @apply bg-grey-250 text-grey-400 border-grey-200;
      }
    }
    &--icon-minimal {
      @apply h-24 w-24 p-3 text-18 leading-none shrink-0 rounded text-center font-normal text-blue-400 hover:bg-blue-400/10;
      &:disabled {
        @apply text-grey-500/25;
      }
    }
    &--plan {
      @apply border-transparent bg-grey-500 rounded-30 py-16;
      &.active {
        @apply bg-blue-400 border-transparent text-white;
      }
      @screen lg {
        @apply w-full mt-auto py-24 rounded-6;
      }
    }
    &--group-pri {
      @apply group-hover:bg-blue-400 group-hover:border-blue-400 group-hover:text-white;
    }
    &--icon-info {
      border: solid 1.5px;
      @apply rounded-full h-20 w-20 border-blue-400 ml-8 text-blue-400 hover:bg-blue-400 hover:text-white;
    }
  }

  .btn__arrow {
    @apply h-12 mb-2 ml-10 w-12;
    transform: rotate(90deg);
  }

  .btn--nav-to-section {
    @apply absolute h-64 w-64;
    top: -32px;
    left: calc(50% - 32px);
  }
}

/*! purgecss start ignore */
.stamped-main-widget .stamped-container,
.stamped-reviews-widget-minimal .stamped-style-color-text {
  @apply font-primary;
}
#stamped-main-widget.stamped-main-widget {
  @apply m-0;

  & .page {
    @apply min-h-0;
  }

  & .stamped-header-title {
    @apply hidden;

    @screen sm {
      @apply block;
    }
  }
}

div[data-rating="5"] .stamped-review-reply {
    display: none;
}

.stamped-review-avatar {
  text-shadow: none;
}

.stamped-location-flag img {
  margin-bottom: 5px;
}

/*! purgecss end ignore */

@use "sass:math";
@use "sass:list";

@mixin flex-container( $width, $height ) {
  height: 0;
  overflow: hidden;
  padding-bottom: (math.div($height, $width) * 100%);
  position: relative;
  z-index: 1;
}

@mixin font-face( $filename, $legacy: false ) {
  @font-face {
    @content;
    font-display: swap;
    @if $legacy {
      src: url('../fonts/#{$filename}.eot');
      src: url('../fonts/#{$filename}.eot?#iefix') format('embedded-opentype'),
      url('../fonts/#{$filename}.woff') format('woff'),
      url('../fonts/#{$filename}.ttf') format('truetype'),
      url('../fonts/#{$filename}.svg') format('svg');
    } @else {
      src: url('../../fonts/#{$filename}.woff2') format('woff2'),
      url('../../fonts/#{$filename}.woff') format('woff');
    }
  }
}

@mixin hi-dpi( $ratio: 1.3 ) {
  @media only screen and ( -webkit-min-device-pixel-ratio: $ratio ),
    only screen and ( min--moz-device-pixel-ratio: $ratio ),
    only screen and ( -o-min-device-pixel-ratio: list.slash($ratio, 1) ),
    only screen and ( min-resolution: round( $ratio * 96dpi ) ),
    only screen and ( min-resolution: $ratio * 1dppx ) {
      @content;
  }
}

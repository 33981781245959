/*! purgecss start ignore */
.newsletter-form {
  & .newsletter-form__main {
    @apply max-w-770 mx-auto w-full;

    // specificity required to override Klaviyo styles :(
    & .klaviyo-form.klaviyo-form.klaviyo-form.klaviyo-form.klaviyo-form-JiS4m4 {
      & form {
        & > div {
          @apply flex flex-col m-0 -mb-10 -ml-10;

          & > div {
            @apply w-full;

            & > div {
              @apply p-0 pl-10 pb-10;
            }
          }
        }
      }
    }

    & .klaviyo-form.klaviyo-form.klaviyo-form.klaviyo-form {
      & * {
        font-family: inherit !important;
      }

      & form {
        & p {
          color: inherit !important;
        }

        & input {
          @apply pt-2;
        }

        & input,
        & [class*="DropdownComponents__DropdownField"] {
          @apply bg-white border border-grey-300 duration-400 ease-in-out font-medium h-48 px-20 rounded text-16 text-left text-blue-900 transition-all;
          box-sizing: border-box;

          &::placeholder {
            @apply font-medium text-16 text-grey-500;
            font-family: inherit;
          }

          @screen md {
            @apply text-13;

            &::placeholder {
              @apply text-13;
            }
          }

          &:hover {
            @apply border-blue-400;
          }
        }

        & [class*="DropdownComponents__DropdownField"] {
          @apply relative z-0;

          &[isopen="true"] {
            @apply border-blue-400;
          }

          &::after {
            @apply absolute pointer-events-none right-20;
            content: url( '../../svg/chevron-down.svg' );
            height: 5px;
            top: 12px;
            width: 8px;
          }

          & input {
            @apply px-0;
            height: 46px;
          }

          & > svg {
            @apply hidden;
          }
        }

        & [class*="DropdownComponents__Items"] {
          @apply bg-white border border-t border-grey-300 mt-10 rounded shadow-grey-10%-sm;
          border-top-style: solid;

          & [class*="DropdownComponents__Item"] {
            @apply font-medium px-20 pt-12 pb-10 text-13 text-grey-500;

            &:not(:last-child) {
              @apply border-b border-grey-300;
              border-bottom-style: solid;
            }

            &:hover {
              @apply text-blue-400;
            }
          }
        }

        & button {
          @apply bg-blue-400 border-blue-400 border caps duration-400 ease-in-out inline-flex items-center justify-center mx-auto mt-14 px-24 rounded-6 text-center text-white transition-all w-full;
          box-sizing: border-box;
          padding-bottom: 1.35rem;
          padding-top: 1.45rem;

          @screen sm {
            @apply w-250;
          }

          @screen lg {
            @apply mt-30;
          }

          &:hover,
          &:focus {
            @apply bg-blue-500 border-blue-500 text-white;
          }

          &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}
/*! purgecss end ignore */

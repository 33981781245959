.embla {
  overflow: hidden;
  &--visible {
    overflow: visible;
  }
}

.embla__container {
  display: flex;

  .is-draggable > & {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
  }
}

.embla__slide {
  flex: 0 0 auto;
  position: relative;
}

.embla__thumb {
  @apply overflow-hidden rounded w-full;

  &:hover,
  &:focus {
    @apply border-3 border-blue-200;
  }

  &.is-active {
    @apply border-3 border-blue-400;
  }
}

// ----- classes -----
@layer utilities {
  // ----- useful extends -----
  %disabled {
    // @apply text-grey-400;
    pointer-events: none;
    user-select: none;
  }

  %display-none {
    display: none;
  }

  .abs-cover {
    height: auto;
    left: 50%;
    max-width: none;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate( -50%,-50% );
    width: auto;
  }

  %abs-fill,
  .abs-fill {
    left: 0;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .dropdown-icon {
    background-size: 10px 6px;
    background-position: center right 17px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' fill='none' viewBox='0 0 10 6'%3E%3Cpath fill='%237D8FA3' d='M0 .71A.71.71 0 011.17.17l3.82 3.19L8.82.28a.71.71 0 011 .1.71.71 0 01-.1 1.05L5.44 4.87a.71.71 0 01-.9 0L.25 1.3A.71.71 0 010 .7z'/%3E%3C/svg%3E");
  }

  .before-hidden {
    &::before {
      @apply hidden;
    }
  }
  .before-block {
    &::before {
      @apply block;
    }
  }
  .after-hidden {
    &::after {
      @apply hidden;
    }
  }
  .after-block {
    &::after {
      @apply block;
    }
  }
  .after-opacity-0 {
    &::after {
      @apply opacity-0;
    }
  }

  .backface-hidden {
    backface-visibility: hidden;
  }

  .hide-scrollbar {
    -ms-overflow-style: -ms-autohiding-scrollbar;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .layered {
    position: relative;
    z-index: 1;
  }

  // .line-clamp {
  //   display: -webkit-box;
  //   -webkit-line-clamp: 2;
  //   -webkit-box-orient: vertical;
  //   overflow: hidden;
  // }

  .min-aspect-ratio {
    &::after {
      clear: left;
      content: '';
      display: table;
    }
  }

  // ----- backgrounds -----
  .bg-wave-diag {
    background-image: url( '../svg/shape-wave-diag.svg' );
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: 765px 478px;
  }

  .bg-none {
    background: none;
  }
  // ----- borders -----
  .border-r-blue-400 {
    border-right-color: theme( 'colors.blue.400' );
  }

  .border-t-blue-400 {
    border-top-color: theme( 'colors.blue.400' );
  }

  // ----- clip -----
  // .clip-sketch-circle {
  //   clip-path: url(#clip-sketch-circle);
  // }

  // ----- animations ------
  // $animation-delays: (40, 60, 80, 120, 160, 180, 200, 240, 300);
  // $animation-durations: (40, 60, 80);
  //
  // .anim-ease-out-quad {
  //   animation-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  // }

  //   @each $x in $animation-delays {
  //     .anim-delay-#{$x} {
  //       animation-delay: #{$x}ms;
  //     }
  //   }
  //
  //   @each $x in $animation-durations {
  //     .anim-#{$x} {
  //       animation-duration: #{$x * 10}ms;
  //     }
  //   }

  .translate-z-0 {
    transform: translateZ( 0 );
  }

  .whitespace-nowrap {
    white-space: nowrap;
  }

  // ----- vue animations -----
  /*! purgecss start ignore */
  .slide-up-out {
    &-enter-active,
    &-leave-active {
      opacity: 1;
      transform: translateY( 0 );
    }

    &-enter {
      opacity: 0;
      transform: translateY( 20px );
    }

    &-leave-to {
      opacity: 0;
      transform: translateY( -20px );
    }
  }
  .fade {
    &-enter-active,
    &-leave-active {
      opacity: 1;
    }

    &-enter,
    &-leave-to {
      opacity: 0;
    }
  }

  .membership-content {
    h3 {
      margin-top: 40px;
      font-size: 30px;
      line-height: 40px;


      @screen md {
        margin-top: 60px;
        font-size: 32px;
        line-height: 42px;
      }
    }

    p {
      margin-top: 25px;
      font-size: 20px;
      line-height: 30px;
    }
  }

  .greyscale-none {
    filter: grayscale(0%);
  }

  .greyscale-100 {
    filter: grayscale(100%);
  }
  /*! purgecss end ignore */
}

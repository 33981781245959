.uncloak {
  transition: opacity 0.25s ease-out;

  &--cloaked {
    opacity: 0;
    pointer-events: none;
  }

  // NB: this attribute is added as craft preview refresh
  // seems to semi-reset uncloak state
  &[data-uncloak-loaded] {
    opacity: 1;
  }

  &--slow {
    transition-duration: 0.35s;
  }

  &--zoom {
    overflow: hidden;
    transition: opacity 0.45s;

    & .uncloak__item {
      transition: transform 0.45s;
    }

    &.uncloak--cloaked {
      & .uncloak__item {
        transform: scale(1.05);
      }
    }
    &[data-uncloak-loaded] {
      & .uncloak__item {
        transform: scale(1);
      }
    }
  }
}

.uncloak__item {
  .uncloak--cloaked & {
    @apply opacity-0;
  }
}

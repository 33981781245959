/*! purgecss start ignore */
body {
  .klav-embed-form .klaviyo-form {
    & * {
      font-family: theme( 'fontFamily.primary' ) !important;
    }

    & input {
      border-radius: 3px !important;
    }

    & form[class*='View__FormView'] {
      background-color: transparent !important;
    }

    & div[class*='TextInput__StyledInputContainer'] {
      & label {
        padding-bottom: 28px !important;
        text-align: center !important;
      }
    }

    & button[class*='Button__FormStyledButton'] {
      @apply duration-300 ease-in-out;
      box-sizing: border-box;
      min-height: 64px;
      transition-property: all;

      &:hover,
      &:focus {
        background-color: theme( 'colors.blue.500' ) !important;
      }
    }
  }
}

/*! purgecss end ignore */

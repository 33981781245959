@layer components {
  .container {
    @apply max-w-1440 mx-auto w-full;

    &--default {
      @apply px-20;

      @screen sm {
        @apply px-32;
      }

      @screen lg {
        @apply px-72;
      }

      @screen xl {
        @apply px-90;
      }
    }

    &--tight {
      @apply max-w-550;
    }
  }
}

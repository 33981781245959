$font-primary: 'Gilroy';

@include font-face( 'Gilroy-Medium' ) {
  font-family: $font-primary;
  font-style: normal;
  font-weight: 500;
}

@include font-face( 'Gilroy-MediumItalic' ) {
  font-family: $font-primary;
  font-style: italic;
  font-weight: 500;
}

@include font-face( 'Gilroy-Semibold' ) {
  font-family: $font-primary;
  font-style: normal;
  font-weight: 600;
}

@include font-face( 'Gilroy-Bold' ) {
  font-family: $font-primary;
  font-style: normal;
  font-weight: 700;
}

@include font-face( 'Gilroy-Extrabold' ) {
  font-family: $font-primary;
  font-style: normal;
  font-weight: 800;
}

@include font-face( 'Gilroy-Heavy' ) {
  font-family: $font-primary;
  font-style: normal;
  font-weight: 900;
}
